import { useEffect, useState } from "react";
import { PayPalScriptProvider, PayPalButtons, FUNDING } from "@paypal/react-paypal-js";
import axios from "axios";

const PayPalPayment = ({ fundingSource, amount, payee, fee, onOrder }) => {
	const [test, setTest] = useState('')
	return (
		<PayPalButtons
			fundingSource={fundingSource}
			style={{ layout: "vertical" }}
			createOrder={async () => {
				try {
					const response = await axios.post("/jobs/payment/create", {
						amount,
						payee,
						fee,
					});
					console.log("response", response.data.orderId.orderId);
					return response.data.orderId.orderId;
				} catch (error) {
					console.error("Error creating order:", error);
					return null;
				}
			}}
			onApprove={async (data) => {
				try {
					const response = await axios.post("/jobs/payment/capture", {
						orderID: data.orderID,
					});
					onOrder(response.data.paymentID);
				} catch (error) {
					console.error("Error capturing payment:", error);
				}
			}}
		/>
	);
};



const PaypalButton = ({ amount, payee, onOrder }) => {


	const original_amount = (amount / 1.2).toFixed(2)
	const fee = amount - original_amount;

	// Card fee: 2.99% + 0.49 on the platform's 20% cut
	const cardFee = fee - (amount * 0.0299 + 0.49);

	// PayPal fee: 3.49% + 0.49 on the platform's 20% cut
	const paypalFee = fee - (amount * 0.0349 + 0.49);

	const applePayFee = fee - (amount * 0.0409 + 0.49);

	return (

		<div className="paypal-pay-button">
			<PayPalPayment fundingSource={FUNDING.PAYPAL} amount={amount} payee={payee} fee={paypalFee} onOrder={onOrder} />
			<PayPalPayment fundingSource={FUNDING.APPLEPAY} amount={amount} payee={payee} fee={applePayFee} onOrder={onOrder} />
			<PayPalPayment fundingSource={FUNDING.CARD} amount={amount} payee={payee} fee={cardFee} onOrder={onOrder} />
		</div>

	);
}

export default PaypalButton;